import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div>
      <div
        style={{
          display: "flex",
          height: "150px",
          lineHeight: "1",
          justifyContent: "space-around",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            boxShadow:
              "rgb(7, 29, 91) 0.5rem 0px 0px, rgb(7, 29, 91) -0.5rem 0px 0px",
            backgroundColor: "rgb(94, 136, 193)",
            // color: "rgb(247, 178, 51)",
            color: "white",
            lineHeight: "1",
            padding: "0.25em"
          }}
        >
          Not Found
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow:
              "rgb(7, 29, 91) 0.5rem 0px 0px, rgb(7, 29, 91) -0.5rem 0px 0px",
            backgroundColor: "rgb(94, 136, 193)",
            color: "white",
            lineHeight: "1",
            padding: "0.25em"
          }}
        >
          Unfortunately, the address does not exists.
        </h3>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
